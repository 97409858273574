import { useEffect, useState } from 'react';

interface IExpand {
  index: number;
  media: string;
  title: string;
  text: string;
  expandActive: string[];
  setExpandActive: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function ExpandItem({ index, media, title, text, expandActive, setExpandActive }: IExpand) {
  const updateExpandActive = () => {
    let updatedClassNames = ['', '', ''];
    updatedClassNames[index] = 'active';
    setExpandActive(updatedClassNames);
  };
  return (
    <li
      className={`expandcard_acc__item ${expandActive[index] === 'active' && 'active'}`}
      key={index}
      onClick={() => updateExpandActive()}
    >
      <video className='expandcard_acc__item--back' preload='auto' loop muted playsInline autoPlay>
        <source src={media} type='video/mp4' />
      </video>
      <div className='expandcard_acc__item__inner'>
        <div className='expandcard_acc__item__title'>
          <span>{title}</span>
          <p>{text}</p>
        </div>
      </div>
    </li>
  );
}
