import * as fetcher from '../helpers/fetcher';

export async function postRegister(address: string, signature: string, nickname: string, imageData: any) {
  const body = new FormData();
  try {
    body.append('walletAddress', address);
    body.append('signature', signature);
    body.append('nickname', nickname);
    if (imageData) {
      body.append('avatar', imageData);
    }
    const user = await fetcher.post('/auth/register', {}, body);
    const body2 = {
      type: 'registration_start',
      casinoId: null,
      payload: {}
    };
    await fetcher.post(
      '/metrics',
      {
        Authorization: `Bearer ${user.accessToken}`
      },
      body2
    );

    return user;
  } catch (err: any) {
    throw new Error(`${err.message}`);
  }
}
