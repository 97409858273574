import { SwiperSlide } from 'swiper/react';

import '../../../App.css';

import IconLinked from '../../../images/redesign/icon-linked.svg';
import { useState } from 'react';

interface ITeam {
  image: string;
  name: string;
  position: string;
  textPoints: string[];
  linkedin: string;
}

export default function TeamCard({ image, name, position, textPoints, linkedin }: ITeam) {
  const [active, setActive] = useState(false);
  return (
    // <div className='team__slider__grid__item swiper-slide' key={index}>
    <div className={!active ? 'team__slider__card' : 'team__slider__card active'} onMouseLeave={() => setActive(false)}>
      <img className='team__slider__card--person' loading='lazy' src={image ? String(image) : ''} alt='' />
      <div className='team__slider__card--name'>
        <span>{name}</span>
        <i>{position}</i>
      </div>
      <div className='team__slider__card---overlay'></div>
      <div className='team__slider__card--btn' onClick={() => setActive(true)}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -960 960 960'>
          <path
            fill='currentColor'
            d='M517.847-480 354.924-642.924q-8.308-8.307-8.5-20.884-.193-12.576 8.5-21.268 8.692-8.693 21.076-8.693t21.076 8.693l179.769 179.769q5.616 5.615 7.923 11.846 2.308 6.23 2.308 13.461t-2.308 13.461q-2.307 6.231-7.923 11.846L397.076-274.924q-8.307 8.308-20.884 8.5-12.576.193-21.268-8.5-8.693-8.692-8.693-21.076t8.693-21.076L517.847-480Z'
          />
        </svg>
      </div>
      <div className='team__slider__card__back'>
        <span>{name}</span>
        <i>{position}</i>
        <div>
          {(textPoints as string[]).map((point: any, i: any) => (
            <p key={i}>{point}</p>
          ))}
        </div>
        <div className='team__slider__card__back--link'>
          {linkedin && (
            <a href={String(linkedin)} target='_blank' rel='noreferrer'>
              <img src={IconLinked} alt='' />
            </a>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
}
