import * as fetcher from '../helpers/fetcher';

export async function demoContactUs(formData: {}) {
  try {
    return await fetcher.post('/demo-form/apply', {}, formData);
  } catch (error) {
    console.error('Error submitting form:', error);
  }
}

export async function demoNewsletter(email: string) {
  try {
    return await fetcher.post('/demo-form/newsletter', {}, {email: email});
  } catch (error) {
    console.error('Error submitting form:', error);
  }
}
