import React, { createContext, useContext, useEffect, useState } from 'react';

import { ConnectionContext } from './Connection';

import { getUser as fetchUser } from '../services/userService';

import * as fetcher from '../helpers/fetcher';

interface IAuthControllerContext {
  showChangeAddressModal: boolean | null;
  setShowChangeAddressModal: React.Dispatch<React.SetStateAction<boolean>>;
  isSignMessage: boolean;
  setIsSignMessage: React.Dispatch<React.SetStateAction<boolean>>;
  modalPopup: boolean;
  setModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  disconnectUser: (disconnectWallet?: boolean) => Promise<void>;
  user: any;
  setUser: React.Dispatch<React.SetStateAction<UserInterface | undefined>>;
  fetchingUser: boolean;
  setFetchingUser: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getUser: (token?: string) => Promise<object | null | undefined>;
}

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const defaultValue: IAuthControllerContext = {
  showChangeAddressModal: false,
  setShowChangeAddressModal: () => {},
  isSignMessage: false,
  setIsSignMessage: () => {},
  modalPopup: false,
  setModalPopup: () => {},
  disconnectUser: async (disconnectWallet?: boolean): Promise<void> => {},
  user: null,
  setUser: () => {},
  fetchingUser: false,
  setFetchingUser: () => {},
  loading: false,
  setLoading: () => {},
  getUser: async (token?: string): Promise<object | null | undefined> => {
    return Promise.resolve(null); // Default implementation returns null
  }
};

type UserInterface = {
  id: string;
  shortId: string;
  walletId: string;
  brutMoney: number;
  nickname: string;
  kycState: string;
  kycIntreviewId: string;
  referral: string;
  lastLocation: string;
  isMetasavageNFTOwner: boolean;
  lastLoginTimestampUTC: Date;
  createdDate: Date;
  avatarUrl: any;
  casinoTokens: any;
  locatedInCasinoId: string | null;
  locatedInCasino: string | null;
  isDemo: boolean;
  twoFactorAuthenticationSecret: string | null;
  isTwoFactorAuthenticationEnabled: boolean;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: Date;
  phone: string | null;
  city: string | null;
  postalCode: string | null;
  address: string | null;
  country: string | null;
  gender: string | null;
};
export const AuthControllerContext = createContext(defaultValue);

export const AuthControllerProvider = ({ children }: Props) => {
  const { provider, wallet, disconnect, connecting } = useContext(ConnectionContext);
  const [isSignMessage, setIsSignMessage] = useState<boolean>(false);
  const [user, setUser] = useState<UserInterface | undefined>(undefined);
  const [showChangeAddressModal, setShowChangeAddressModal] = useState<boolean>(false);
  const [modalPopup, setModalPopup] = useState<boolean>(false);
  const [fetchingUser, setFetchingUser] = useState<boolean>(true);
  const [isUserConnected, setIsUserConnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const addressLocalStorage = localStorage.getItem('address');

  useEffect(() => {
    checkForToken();
  }, [connecting]);

  useEffect(() => {
    const getSignMessage = async () => {
      // :TODO Hare we are checking the chan for feature implementation
      // if (wallet?.chains[0]?.id === ('0x5' || '0x1')) {

      if (provider && wallet?.accounts[0].address) {
        setIsUserConnected(true);
        if (localStorage.getItem('bt')) {
          if ((await fetcher.ValidateToken(localStorage.getItem('bt'), wallet.accounts[0].address)) !== 'verified') {
          } else {
            await getUser();
          }
          setFetchingUser(false);
        } else {
          await getUser();
        }
      } else {
        if (isUserConnected) {
          setIsUserConnected(false);
          disconnectUser();
        }
      }
      // }
    };

    getSignMessage();
  }, [provider, wallet]);

  useEffect(() => {
    if (wallet?.accounts[0].address && addressLocalStorage && addressLocalStorage !== wallet?.accounts[0].address) {
      setShowChangeAddressModal(true);
    } else {
      setShowChangeAddressModal(false);
    }
  }, [wallet?.accounts[0]]);

  async function disconnectUser(disconnectWallet?: boolean) {
    if (wallet) {
      if (!disconnectWallet) {
        await disconnect(wallet);
      }
      try {
        fetcher.get('/auth/logout', {}, {});
      } catch (error) {}
    }

    setUser(undefined);
    localStorage.removeItem('bt');
    localStorage.removeItem('address');
    localStorage.removeItem('connectedWallets');

    // Delete all the store properties from walletConnect connection
    for (var key in localStorage) {
      if (key.startsWith('wc@2')) {
        sessionStorage.removeItem(key);
      }
    }
  }

  async function getUser(token?: string): Promise<any> {
    let address;
    if (token) {
      address = fetcher.getAddressFromToken(token);
    } else {
      address = fetcher.getAddressFromToken(localStorage.getItem('bt'));
    }

    if (!address) {
      return address;
    }
    try {
      const currentUser = await fetchUser(address);

      setUser(currentUser);
      setFetchingUser(false);
      return currentUser;
    } catch (err: any) {
      disconnectUser();
    }
  }

  const checkForToken = async (): Promise<boolean> => {
    const address = localStorage.getItem('address');

    if (address) {
      if ((await fetcher.ValidateToken(localStorage.getItem('bt'), address)) === 'invalid-token') {
        disconnectUser();
      }
    }
    setFetchingUser(false);

    if (localStorage.getItem('bt')) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AuthControllerContext.Provider
      value={{
        showChangeAddressModal,
        setShowChangeAddressModal,
        isSignMessage,
        setIsSignMessage,
        modalPopup,
        setModalPopup,
        disconnectUser,
        user,
        setUser,
        fetchingUser,
        setFetchingUser,
        loading,
        setLoading,
        getUser
      }}
    >
      {children}
    </AuthControllerContext.Provider>
  );
};
