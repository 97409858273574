import * as fetcher from '../helpers/fetcher';

export async function postUserSessionStart(accessToken: string | null) {
  const body = new FormData();
  try {
    const body2 = {
      type: 'user_session_start',
      payload: {}
    };
    await fetcher.post(
      '/metrics',
      {
        Authorization: `Bearer ${accessToken}`
      },
      body2
    );
  } catch (err: any) {
    throw new Error(`${err.message}`);
  }
}

export async function postUserSessionEnd(accessToken: string | null) {
  const body = new FormData();
  try {
    const body2 = {
      type: 'user_session_end',
      payload: {}
    };
    await fetcher.post(
      '/metrics',
      {
        Authorization: `Bearer ${accessToken}`
      },
      body2
    );
  } catch (err: any) {
    throw new Error(`${err.message}`);
  }
}

export async function postSessionStart(accessToken: string | null) {
  const body = new FormData();
  try {
    const body2 = {
      type: 'session_start',
      payload: {}
    };
    await fetcher.post(
      '/metrics',
      {
        Authorization: `Bearer ${accessToken}`
      },
      body2
    );
  } catch (err: any) {
    throw new Error(`${err.message}`);
  }
}

export async function postSessionEnd(accessToken: string | null) {
  const body = new FormData();
  try {
    const body2 = {
      type: 'session_end',
      payload: {}
    };
    await fetcher.post(
      '/metrics',
      {
        Authorization: `Bearer ${accessToken}`
      },
      body2
    );
  } catch (err: any) {
    throw new Error(`${err.message}`);
  }
}

export async function heartbeat(accessToken: string | null) {
  if (!accessToken) {
    return;
  }
  return await fetcher.post(
    '/metrics/heartbeat',
    {
      Authorization: `Bearer ${accessToken}`
    },
    {}
  );
}

export async function heartbeatUnreal(accessToken: string | null) {
  if (!accessToken) {
    return;
  }
  return await fetcher.post(
    '/metrics/heartbeat-unreal',
    {
      Authorization: `Bearer ${accessToken}`
    },
    {}
  );
}
