import { useEffect, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';

import { AuthControllerContext } from '../../context/AuthController';

import { setNewAccessToken } from '../../helpers/fetcher';

import CloseButton from '../../images/login/close-button.png';

import { PIXEL_STREAMING_URL } from '../../constants';

import { heartbeatUnreal } from '../../services/metricsService';
import './PixelStreaming.scss';

const PixelStreaming = () => {
  const { user, disconnectUser } = useContext(AuthControllerContext);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [slotMachineIframeUrl, setSlotMachineIframeUrl] = useState<string>('');
  const [height, setHeight] = useState<number>(window.innerHeight);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    // This is when all the element in the page are load to sent message with token
    window.addEventListener('message', sendMessageHandler);
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('storage', sendMessageHandler);
    };
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      const handleIframeLoad = () => {
        onLoadHandler();
      };

      iframe.addEventListener('load', handleIframeLoad);

      return () => {
        iframe.removeEventListener('load', handleIframeLoad);
      };
    }
  }, []);

  useEffect(() => {
    handleIframeChanges();
  }, [slotMachineIframeUrl]);

  async function handleIframeChanges() {
    const iframePixel: HTMLIFrameElement | null = document.querySelector('.pixel-iframe');
    const iframeSlotMachine: HTMLIFrameElement | null = document.querySelector('.slot-machine-iframe');

    if (!iframePixel || !iframeSlotMachine) {
      return;
    }

    if (slotMachineIframeUrl) {
      if (divRef.current && document.fullscreenElement) {
        document.exitFullscreen();
      }
      iframePixel.style.display = 'none';
      iframeSlotMachine.style.display = 'block';
    } else {
      iframePixel.style.display = 'block';
      iframeSlotMachine.style.display = 'none';
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (user) {
        heartbeatUnreal(localStorage.getItem('bt'));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [user]);
  const onLoadHandler = () => {
    sendMessage();
  };

  const sendMessageHandler = (event: any): void => {
    console.log('11111111111111111111111111111111111111111111111111111111111111111111111111111', event);

    // if (event.origin !== PIXEL_STREAMING_URL) {
    //   return;
    // } // IFRAME WORKAROUND
    const message = event.data;

    if (message.status == 'token') {
      if (message.token == 'expire') {
        setNewAccessToken()
          .then(() => {
            sendMessage();
          })
          .catch((err) => {
            disconnectUser();
          });
      } else if (message.token == 'invalid') {
        disconnectUser();
      }
    }
    console.log('message', message);
    if (message.Action == 'OpenFrame') {
      console.log('here', message.url);
      setSlotMachineIframeUrl(message.url);
    } else if (message.Action == 'CloseFrame') {
      setSlotMachineIframeUrl('');
    } else if (message.Action == 'Disconnect') {
      window.location.href = '/';
    }
    // if (message.url) {
    //   setSlotMachineIframeUrl(message.url);
    // } else {
    //   setSlotMachineIframeUrl('');
    // }
  };

  const handleStorageChange = (event: any) => {
    console.log(event);
    if (event.key === 'bt') {
      sendMessage();
    }
  };

  function sendMessage(messageIframe?: boolean) {
    const iframe = iframeRef.current;
    let message;
    if (messageIframe) {
      message = {
        status: 'closeSlotIframe'
      };
    } else {
      message = {
        status: 'token',
        token: localStorage.getItem('bt'),
        deviceType: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
      };
    }

    const targetOrigin = '*'; // PIXEL_STREAMING_URL; // replace with your child window's domain
    console.log('sending message', iframe?.contentWindow, targetOrigin);
    if (iframe && iframe.contentWindow && targetOrigin) {
      iframe.contentWindow.postMessage(message, targetOrigin);
    }
  }

  // This is to display on full screen
  // useEffect(() => {
  //   // if (/Android/i.test(navigator.userAgent)) {

  //   console.log(iframeRef.current, 'iframeRef.current');
  //   if (iframeRef.current) {
  //     const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;

  //     console.log('iframeDocumentiframeDocument', iframeDocument?.fullscreenElement);
  //     console.log('requestFullscreen', iframeRef.current);
  //     iframeRef.current.requestFullscreen();
  //   }
  //   // }
  // }, [iframeRef]);

  return (
    <div className='pixelstream position-fixed' ref={divRef}>
      <iframe
        className='pixel-iframe'
        ref={iframeRef}
        src={PIXEL_STREAMING_URL}
        style={{ width: width, height: height }}
        allowFullScreen={true}
      ></iframe>
      {slotMachineIframeUrl ? (
        <div className='close-button'>
          <img
            src={CloseButton}
            alt='close-button'
            onClick={() => {
              sendMessage(true);
              setSlotMachineIframeUrl('');
            }}
          />
        </div>
      ) : (
        ''
      )}
      {/* This iframe is when the PixelStreaming return url for slot machine  */}
      <iframe
        className='slot-machine-iframe'
        src={slotMachineIframeUrl}
        style={{ width: '100vw', height: '100vh' }}
        allowFullScreen={true}
      ></iframe>
      ;
      {/* <iframe src='http://localhost' style={{ width: '100vw', height: '100vh' }} allowFullScreen={true}></iframe>; */}
      {/* <iframe src='http://localhost' style={{ width: '800px', height: '800px' }}></iframe>; */}
      {/*  <iframe src={PIXEL_STREAMING_URL} style={{ width: '800px', height: '800px' }}></iframe>; */}
    </div>
  );
};

export default PixelStreaming;
