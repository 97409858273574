import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { ethers } from 'ethers';

import { SERVER_URL } from '../constants';

interface IJwtPayload {
  exp: number;
  iat: number;
  payload: {
    walletAddress: string;
  };
}

export const authRequest = async (
  axiosRequest: any,
  userAddress: string,
  endpoint: string,
  headers: any = {},
  body: any = {}
) => {
  try {
    let token = localStorage.getItem('bt');

    const tokenValidation = await ValidateToken(token, userAddress);

    if (tokenValidation.toString() === 'invalid-token') {
      window.location.href = `${window.location.origin}?login=1`;
    } else if (tokenValidation.toString() === 'expire') {
      token = await setNewAccessToken();
    }
    headers.Authorization = `Bearer ${token}`;

    return axiosRequest(endpoint, headers, body);
  } catch (err: any) {
    localStorage.clear();
    throw new Error(`Error-authRequest: ${err.message}`);
  }
};

export const post = async (endpoint: string, headers: any, body: any) => {
  let url = endpoint;
  if (!endpoint.startsWith('http')) {
    url = `${SERVER_URL}/api${endpoint}`;
  }

  return axios
    .post(url, body, {
      headers,
      withCredentials: true,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        case 201:
          return res.data;
        default:
          throw new Error(res.data.message);
      }
    });
};

export const patch = async (endpoint: string, headers: any, body: any) => {
  return axios
    .patch(`${process.env.REACT_APP_SERVER_URL}/api${endpoint}`, body, {
      headers,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.message);
      }
    });
};

export const deleteReq = async (endpoint: string, headers: any, body: any) => {
  return axios
    .delete(`${SERVER_URL}/api${endpoint}`, {
      headers,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.message);
      }
    });
};

export const get = async (endpoint: string, headers: any, body = {}) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api${endpoint}`, {
      headers,
      withCredentials: true,
      validateStatus: () => true,
      params: body
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        case 201:
          return res.data;
        default:
          throw new Error(res.data.message);
      }
    })
    .catch((err: any) => {
      throw new Error(err.message);
    });
};

export const ValidateToken = async (token: string | null, userAddress: string): Promise<string> => {
  if (!token) {
    return 'invalid-token';
  }

  // if (token) {
  //   return 'invalid-token';
  // }

  const decoded: IJwtPayload = jwtDecode(token);
  const now = new Date().getTime();

  // I am adding this to be  minus 3 seconds because we have request going throw another service
  // and after that this server sending request to the main server and i want the token to be valid during this request
  if (now - 3000 > decoded.exp * 1000) {
    try {
      await setNewAccessToken();
      return 'verified';
    } catch (error) {
      throw new Error('Invalid token');
    }
  }
  if (userAddress.toLocaleLowerCase() !== decoded.payload.walletAddress.toLocaleLowerCase()) {
    return 'invalid-token';
  }

  return 'verified';
};

export const setNewAccessToken = async () => {
  try {
    const newAccessToken = await get(`/auth/refresh`, {}, {});

    localStorage.setItem('bt', newAccessToken.accessToken);
    return newAccessToken.accessToken;
  } catch (err: any) {
    localStorage.removeItem('bt');

    throw new Error(err.message);
  }
};

export const getAddressFromToken = (token: string | null) => {
  if (!token) {
    return null;
  }
  const decoded: IJwtPayload = jwtDecode(token);
  return decoded.payload.walletAddress;
};
