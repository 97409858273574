import { ReactComponent as TokaCity } from '../../images/footer/logo.svg';
import { ReactComponent as PlayInWeb } from '../../images/footer/footer_web.svg';
import { ReactComponent as Phone } from '../../images/footer/footer_phone.svg';
import { ReactComponent as Installation } from '../../images/footer/footer_installation.svg';
import { ReactComponent as OsDependencies } from '../../images/footer/footer_os_deps.svg';
import { ReactComponent as Apple } from '../../images/footer/footer_apple.svg';
import { ReactComponent as Android } from '../../images/footer/footer_android.svg';
import { ReactComponent as Desktop } from '../../images/footer/footer_desktop.svg';
import { ReactComponent as ScreenSize } from '../../images/footer/footer_screensize.svg';
import { ReactComponent as Cpu } from '../../images/footer/footer_cpu.svg';
import { ReactComponent as Facebook } from '../../images/icons/facebook.svg';
import { ReactComponent as Twitter } from '../../images/icons/twitter.svg';
import { ReactComponent as Instagram } from '../../images/icons/instagram.svg';
import { ReactComponent as LinkedIn } from '../../images/icons/footer_linkedin.svg';
import { useLocation, Link } from 'react-router-dom';
import * as fetcher from '../../helpers/fetcher';
import '../../App.css';
import './Footer.scss';
import Characters from '../../images/redesign/characters.svg';
import CharactersGirl from '../../images/redesign/charactersGirl.svg';
import LogoFooter from '../../video/redesign/footer_logo.mp4';
import Icon1 from '../../images/redesign/icon-linked.svg';
import Icon2 from '../../images/redesign/icon-twitter.svg';
import Icon3 from '../../images/redesign/icon-fb.svg';
import Icon4 from '../../images/redesign/icon-insta.svg';
import Icon5 from '../../images/redesign/icon-tele.svg';
import Icon6 from '../../images/redesign/icon-yt.svg';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { demoNewsletter } from '../../services/demoService';
interface IFooter {
  setLoginSteps: React.Dispatch<React.SetStateAction<number>>;
}

function Footer({ setLoginSteps }: IFooter) {
  const location = useLocation();
  if (location?.pathname === '/game-start') {
    return null;
  }
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    demoNewsletter(email);
  };

  const charactersRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    observer = new IntersectionObserver(handleIntersect, { threshold: 0 });

    if (charactersRef.current) {
      observer.observe(charactersRef.current);
    }

    return () => {
      if (observer && charactersRef.current) {
        observer.unobserve(charactersRef.current);
      }
    };
  }, [charactersRef]);
  return (
    <>
      <div className='footer__anim' ref={charactersRef}>
        <img src={Characters} alt='' className={`${isVisible ? 'animate char' : 'char'}`} />
        <img src={CharactersGirl} alt='' className={`${isVisible ? 'girl animate' : 'girl'}`} />
      </div>
      <footer className='footer' id='contacts'>
        {/* <div className='footer__banner ajaxform2 hide-md'>
          <div className='form-show-default'>
            <span className='footer__banner__head'>Get the latest information from us</span>

            <form className='footer__banner__input' onSubmit={handleSubmit}>
              <input
                name='email'
                type='email'
                placeholder='Enter your email here'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className='bookf__box__form-btn' type='submit'>
                Subscribe
              </button>
            </form>
          </div>
          <div className='form-show-success'>
            <span className='footer__banner__head'>You have been subscribed to our newsletter!</span>
          </div>
        </div> */}
        <div className='footer__wrapper'>
          <div className='footer__logo'>
            <video preload='auto' loop muted playsInline autoPlay data-poster=''>
              <source src={LogoFooter} type='video/mp4' />
            </video>
          </div>
          {/* <div className='show-on-md ajaxform2'>
            <form className='footer__banner__input' onSubmit={handleSubmit}>
              <input
                name='email'
                type='email'
                placeholder='Enter your email here'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className='bookf__box__form-btn theme-back' type='submit'>
                Subscribe
              </button>
            </form>
          </div> */}
          <div className='footer__content'>
            <div className='footer__content__links'>
              <a href='#' className='footer__content__links--item'>
                <span>Contacts</span>
              </a>
              <a href='#' className='footer__content__links--item'>
                <span>Privacy Policy</span>
              </a>
              <div className='footer__content__links--item hide-md'>
                <span>Follow us</span>
              </div>
              <a href='/TokaCity_MediaKit.pdf' download className='footer__content__links--item'>
                <span>Media kit</span>
              </a>
              <a href='#' className='footer__content__links--item'>
                <span>Cookie Policy</span>
              </a>
              <div className='footer__content__links--icons'>
                <span className='show-on-md'>Follow us</span>
                <a
                  href='https://www.linkedin.com/company/tokacity/mycompany/verification/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={Icon1} alt='' />
                </a>
                <a href='https://twitter.com/TOKA_city' target='_blank' rel='noreferrer'>
                  <img src={Icon2} alt='' />
                </a>
                <a href='https://www.instagram.com/tokacity_casinoverse/' target='_blank' rel='noreferrer'>
                  <img src={Icon4} alt='' />
                </a>
                <a href='' target='_blank' rel='noreferrer'>
                  <img src={Icon5} alt='' />
                </a>
                <a href='https://www.youtube.com/@tokacity.casinoverse' target='_blank' rel='noreferrer'>
                  <img src={Icon6} alt='' />
                </a>
              </div>
            </div>
            <div className='footer__content__info'>
              <span>© {currentYear} TokaCity. All rights reserved.</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
