import { Navigate, Outlet } from 'react-router-dom';

interface IAuthGuard {
  user: any;
  navigateAddress: string;
  setLoginSteps: React.Dispatch<React.SetStateAction<number>>;
}
const AuthGuard = ({ user, navigateAddress, setLoginSteps }: IAuthGuard) => {
  function openLoginModal() {
    setLoginSteps(1);

    return <Navigate to={navigateAddress} />;
  }
  return user ? <Outlet /> : openLoginModal();
};

export default AuthGuard;
