import * as fetcher from '../helpers/fetcher';

export async function getUser(address: string) {
  return fetcher.authRequest(fetcher.get, address, `/user/${address}`);
}

export async function deleteUser(address: string, userId: string) {
  return fetcher.authRequest(fetcher.deleteReq, address, `/user?userId=${userId}`);
}

export async function getUserCasinosToken(address: string) {
  return fetcher.authRequest(fetcher.get, address, `/user/get-all-casinos-tokens`);
}

export async function updateUserAvatar(address: string, avatar: File | undefined) {
  const body = new FormData();
  if (avatar) {
    body.append('avatar', avatar);
  }
  try {
    return fetcher.authRequest(fetcher.post, address, `/user/upload-avatar`, {}, body);
  } catch (err) {
    throw new Error(`Error:${err} `);
  }
}

export async function checkUserWithdraws(address: string) {
  try {
    return fetcher.authRequest(fetcher.get, address, `/user/check-withdraws`, {});
  } catch (err) {
    throw new Error(`Error:${err} `);
  }
}

export async function getPlayerStatistics(address: string) {
  try {
    const res = await fetcher.authRequest(fetcher.get, address, `/user/statistics`);
    if (res && Array.isArray(res)) {
      // Calculate the sum of statistics from all casinos
      const sumObject = res.reduce(
        (acc: any, item: any) => {
          for (const key in item) {
            if (key !== 'casino_name') {
              acc[key] = (acc[key] || 0) + parseFloat(item[key]);
            }
          }
          return acc;
        },
        {
          casino_name: 'Total'
        }
      );
      return sumObject;
    } else {
      return {
        casino_name: 'Total',
        current_balance: 0,
        earnings: 0,
        games_won: 0,
        max_money_won: 0,
        money_cashed_out: 0,
        money_spent: 0,
        starting_balance: 0,
        time_spent: 0
      };
    }
  } catch (err) {
    console.log(err);
    return {
      casino_name: 'Total',
      current_balance: 0,
      earnings: 0,
      games_won: 0,
      max_money_won: 0,
      money_cashed_out: 0,
      money_spent: 0,
      starting_balance: 0,
      time_spent: 0
    };
  }
}

export async function getPlayerTime(address: string) {
  try {
    const res = await fetcher.authRequest(fetcher.get, address, `/user/time-spent`);
    if (res) {
      return res[0].time_spent;
    } else {
      return 0;
    }
  } catch (err) {
    throw new Error(`Error:${err} `);
  }
}

export async function getFriends(address: string) {
  try {
    const res = fetcher.authRequest(fetcher.get, address, `/user/friends`);

    return res;
  } catch (err) {
    throw new Error(`Error:${err} `);
  }
}

export async function updateUserDetails(address: string, formData: any) {
  try {
    return fetcher.authRequest(
      fetcher.post,
      address,
      `/user/update-user-details`,
      {},
      {
        email: formData.email ? formData.email : null,
        nickname: formData.nickname ? formData.nickname : null,
        phone: formData.phone ? `${formData.phoneCode}${formData.phone}` : null,
        city: formData.city ? formData.city : null,
        postalCode: formData.postalCode ? formData.postalCode : null
      }
    );
  } catch (err) {
    throw new Error(`Error:${err} `);
  }
}

export async function getUserDetails(address: string) {
  try {
    return fetcher.authRequest(
      fetcher.get, address, '/user/me', {}, {});
  } catch (err) {
    throw new Error(`Error:${err} `);
  }
}
