import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface IAnimated {
  endValue: number;
  showZero: boolean;
  duration: number;
}

const AnimatedNumber = ({ endValue, showZero, duration }: IAnimated) => {
  const targetRef = useRef<HTMLSpanElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: isVisible ? endValue : 0 },
    config: { duration: duration } // Adjust duration as needed
  });

  const formattedNumber = number.interpolate((value) => {
    // Check if the number has decimal places
    const hasDecimalPlaces = value % 1 !== 0;

    if (hasDecimalPlaces) {
      // Display with two decimal places if it's a double
      return value.toLocaleString(undefined, {
        minimumFractionDigits: showZero ? 2 : 0,
        maximumFractionDigits: showZero ? 2 : 0
      });
    } else {
      // Display as an integer if it's an integer
      return Math.floor(value).toLocaleString();
    }
  });

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    observer = new IntersectionObserver(handleIntersect, { threshold: 0.5 });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (observer && targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef]);

  return (
    <animated.span className={'animated-number'} ref={targetRef}>
      {formattedNumber}
    </animated.span>
  );
};

export default AnimatedNumber;
