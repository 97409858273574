if (!process.env.REACT_APP_KYC_API_URL) {
  throw new Error(`'REACT_APP_KYC_API_URL' env variable is not provided!`);
}
export const KYC_API_URL = process.env.REACT_APP_KYC_API_URL;

if (!process.env.REACT_APP_KYC_FLOW_ID) {
  throw new Error(`'REACT_APP_KYC_FLOW_ID' env variable is not provided!`);
}
export const KYC_FLOW_ID = process.env.REACT_APP_KYC_FLOW_ID;

export const PROVIDER_API_KEY = process.env.REACT_APP_ETH_PROVIDER_URL;
if (!process.env.REACT_APP_ETH_PROVIDER_URL) {
  throw new Error(`'REACT_APP_ETH_PROVIDER_URL' env variable is not provided!`);
}

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
if (!process.env.REACT_APP_SERVER_URL) {
  throw new Error(`'REACT_APP_SERVER_URL' env variable is not provided!`);
}
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export const PIXEL_STREAMING_URL = process.env.REACT_APP_PIXEL_STREAMING_URL;
if (!process.env.REACT_APP_PIXEL_STREAMING_URL) {
  throw new Error(`'REACT_APP_PIXEL_STREAMING_URL' env variable is not provided!`);
}

if (!process.env.REACT_APP_FINANCIAL_MODULE_SERVER_URL) {
  throw new Error(`'REACT_APP_FINANCIAL_MODULE_SERVER_URL' env variable is not provided!`);
}

if (!process.env.REACT_APP_WALLETCONNECT_PROJECT_ID) {
  throw new Error(`'REACT_APP_WALLETCONNECT_PROJECT_ID' env variable is not provided!`);
}
export const WALLETCONNECT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

export const FINANCIAL_MODULE_SERVER_URL = process.env.REACT_APP_FINANCIAL_MODULE_SERVER_URL;
