import { createContext, useEffect, useState } from 'react';
import { ConnectOptions, WalletState, ConnectedChain } from '@web3-onboard/core';
import { useConnectWallet, useSetChain, useWallets } from '@web3-onboard/react';
import type { Chain } from '@web3-onboard/common';
import { ethers } from 'ethers';

import { initWeb3Onboard } from '../services/connectionService';

interface IChain {
  chains: Chain[];
  connectedChain: ConnectedChain | null;
  settingChain: boolean;
}

interface IConnectionContext {
  wallet: WalletState | null;
  connect: (options?: ConnectOptions | undefined) => Promise<WalletState[]>;
  disconnect: (wallet: any) => Promise<WalletState[]>;
  setChain: any;
  connecting: boolean;
  provider: ethers.providers.Web3Provider | null;
  _chain: IChain;
}

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const defaultValue: IConnectionContext = {
  wallet: null,
  connect: () => Promise.resolve([]),
  disconnect: () => Promise.resolve([]),
  setChain: () => Promise.resolve(),
  connecting: false,
  provider: null,
  _chain: {
    chains: [],
    connectedChain: null,
    settingChain: false
  }
};

export const ConnectionContext = createContext(defaultValue);

export const ConnectionProvider = ({ children }: Props) => {
  const [web3Onboard, setWeb3Onboard] = useState<any>(null);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);

  const [
    {
      wallet, // the wallet that has been connected or null if not yet connected
      connecting // boolean indicating if connection is in progress
    },
    connect, // function to call to initiate user to connect wallet
    disconnect // function to call to with wallet<DisconnectOptions> to disconnect wallet
  ] = useConnectWallet();
  const connectedWallets = useWallets();

  const [
    _chain,
    setChain // function to call to initiate user to switch chains in their wallet
  ] = useSetChain();

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, []);

  useEffect(() => {
    if (!connectedWallets.length) return;

    const connectedWalletsLabelArray = connectedWallets.map(({ label }) => label);
    window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWalletsLabelArray));
  }, [connectedWallets, wallet]);

  useEffect(() => {
    if (!wallet?.provider) {
      setProvider(null);
    } else {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, 'any'));
    }
  }, [wallet]);

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(window.localStorage.getItem('connectedWallets')!);

    if (previouslyConnectedWallets?.length) {
      /*
       * We are checking if we have connected to the current session with wallet
       * If not we are showing connection modal
       * If we are connected we do not show connecting modal
       */
      if (sessionStorage.getItem('wallet-connection')) {
        connectWallet(previouslyConnectedWallets, true);
      } else {
        connectWallet(previouslyConnectedWallets, false);
      }
    }
  }, [web3Onboard, connect]);

  async function connectWallet(previouslyConnectedWallets: Array<string>, showModal: boolean) {
    // When we connect to the wallet, setting property to sessionStorage that we are connected to wallet to don't show connection modal

    connect({ autoSelect: { disableModals: showModal, label: previouslyConnectedWallets[0] } })
      .then((result) => {
        // If we have something in localStorage and we deny to connect to wallet from connection modal on first time opening site we are having empty array, and if is empty we are clear user info from localStorage
        if (result.length <= 0) {
          removeUserInformation();
        } else {
          sessionStorage.setItem('wallet-connection', 'true');
        }
      })
      .catch((err: any) => {
        removeUserInformation();
      });
  }

  function removeUserInformation() {
    localStorage.removeItem('bt');
    localStorage.removeItem('address');
    localStorage.removeItem('connectedWallets');
  }

  return (
    <ConnectionContext.Provider value={{ connect, wallet, connecting, provider, disconnect, setChain, _chain }}>
      {children}
    </ConnectionContext.Provider>
  );
};
