import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Games from '../Games/Games';
import Assets from '../Assets/Assets';
import Documents from '../Documents/Documents';
import SingleGame from '../SingleGame/SingleGame';
import AscendHeader from '../../../images/client-hub/ascend_arena.png';
import FTNHeader from '../../../images/client-hub/ftn_arena.png';
import AscendGallery from '../../../images/client-hub/ascend_gallery.png';
import FTNGallery from '../../../images/client-hub/ftn_gallery.png';
import FavelaSkybots from '../../../images/client-hub/favela-skybots.png';
import FavelaGallery from '../../../images/client-hub/FavelaGallery.png'
import BoundInGold from '../../../images/client-hub/bound-in-gold.png'
import BoundGallery from '../../../images/client-hub/bound-gallery.png'


interface ILogin {
  setAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ClientArea({ setAuthorized }: ILogin) {
  return (
    <div className='client-area'>
      <Header setAuthorized={setAuthorized} />
      <div className='client-area-content'>
        <Routes>
          <Route path='' element={<Games />} />
          <Route path='games' element={<Games />} />
          <Route path='assets' element={<Assets />} />
          <Route path='documents' element={<Documents />} />
          <Route
            path='games/ascend-arena'
            element={
              <SingleGame
                name='Ascend Arena'
                headerImage={AscendHeader}
                video={'https://backendapist.blob.core.windows.net/images/ASCEND%203D%20Trailer.mp4'}
                gallery={[{ url: AscendGallery }]}
                assets={[
                  {
                    name: 'Download all',
                    link: 'https://backendapist.blob.core.windows.net/images/Ascend_Arena_PromotionPackage.zip'
                  },
                  {
                    name: 'Art',
                    link: 'https://backendapist.blob.core.windows.net/images/Ascend_Arena_art.zip'
                  },
                  {
                    name: 'Banners and Social Media',
                    link: 'https://backendapist.blob.core.windows.net/images/Ascend_Arena_banners_and_social_media.zip'
                  },
                  {
                    name: 'Logo and Thumbnails',
                    link: 'https://backendapist.blob.core.windows.net/images/Ascend_Arena_logo_and_thumbnails.zip'
                  },
                  {
                    name: 'Screenshots',
                    link: 'https://backendapist.blob.core.windows.net/images/Ascend_Arena_screenshots.zip'
                  },
                  {
                    name: 'Videos',
                    link: 'https://backendapist.blob.core.windows.net/images/Ascend_Arena_videos.zip'
                  },
                  {
                    name: 'Symbols',
                    link: 'https://backendapist.blob.core.windows.net/images/Ascend_Arena_symbols.zip'
                  },
                  {
                    name: 'Game Sheet',
                    link: '/GameSheet_Tokacity_ASCEND_ARENA.pdf'
                  }
                ]}
                about={`Get ready to experience intense, real-time multiplayer action in ASCEND Arena, a visually immersive 3D Player vs. Player crash game where strategy, timing, and nerves of steel are your greatest assets. Up to 10 players compete in each arena, with only one way to claim victory — be the last player to cash out before the inevitable explosion! If all players fail to cash out in time, the pot is equally divided among them. The ASCEND Arena is Toka’s flagship crash game, combining cutting-edge graphics and the thrilling crash genre into an unmatched PvP experience.`}
              />
            }
          />
          <Route
            path='games/ftn-arena'
            element={
              <SingleGame
                name='FTN Arena'
                headerImage={FTNHeader}
                video={'https://backendapist.blob.core.windows.net/images/ftn_trailer.mp4'}
                gallery={[{ url: FTNGallery }]}
                assets={[
                  {
                    name: 'Download all',
                    link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_PromotionPackage.zip'
                  },
                  {
                    name: 'Art',
                    link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_art.zip'
                  },
                  {
                    name: 'Banners and Social Media',
                    link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_banners_and_social_media.zip'
                  },
                  {
                    name: 'Logo and Thumbnails',
                    link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_logo_and_thumbnails.zip'
                  },
                  {
                    name: 'Screenshots',
                    link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_screenshots.zip'
                  },
                  {
                    name: 'Videos',
                    link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_videos.zip'
                  },
                  {
                    name: 'Symbols',
                    link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_symbols.zip'
                  },
                  {
                    name: 'Game Sheet',
                    link: 'https://backendapist.blob.core.windows.net/images/Tokacity%20FTN%20Gamesheet_NEW_compressed.pdf'
                  }
                ]}
                about={`Get ready to experience intense, real-time multiplayer action in FTN Arena, a visually immersive 3D Player vs. Player crash game where strategy, timing, and nerves of steel are your greatest assets. Up to 10 players compete in each arena, with only one way to claim victory — be the last player to cash out before the inevitable explosion! If all players fail to cash out in time, the pot is equally divided among them. The FTN Arena is Toka’s flagship crash game, combining cutting-edge graphics and the thrilling crash genre into an unmatched PvP experience.`}
              />
            }
          />
          <Route
            path='games/favela-skybots'
            element={
              <SingleGame
                name='Favela Skybots'
                headerImage={FavelaSkybots}
                video={'https://backendapist.blob.core.windows.net/images/Favela%20SkyBots%20Trailer.mp4'}
                gallery={[{ url: FavelaGallery }]}
                assets={[
                  {
                    name: 'Download all',
                    link: 'https://backendapist.blob.core.windows.net/images/Favela_Skybots_PromotionPackage.zip'
                  },
                  {
                    name: 'Art',
                    link: 'https://backendapist.blob.core.windows.net/images/Favela_Skybots_art.zip'
                  },
                  {
                    name: 'Banners and Social Media',
                    link: 'https://backendapist.blob.core.windows.net/images/Favela_Skybots_banners_and_social_media.zip'
                  },
                  {
                    name: 'Logo and Thumbnails',
                    link: 'https://backendapist.blob.core.windows.net/images/Favela_Skybots_logo_and_thumbnails.zip'
                  },
                  {
                    name: 'Screenshots',
                    link: 'https://backendapist.blob.core.windows.net/images/Favela_Skybots_screenshots.zip'
                  },
                  // {
                  //   name: 'Videos',
                  //   link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_videos.zip'
                  // },
                  {
                    name: 'Symbols',
                    link: 'https://backendapist.blob.core.windows.net/images/Favela_Skybots_symbols.zip'
                  },
                  {
                    name: 'Game Sheet',
                    link: 'https://backendapist.blob.core.windows.net/images/Tokacity_FAVELA%20SKYROBOTS_Gamesheet_compressed.pdf'
                  }
                ]}
                about={`Get ready to experience intense, real-time multiplayer action in Favela SkyBots, a visually immersive 3D Player vs. Player crash game where strategy, timing, and nerves of steel are your greatest assets. Up to 10 players compete in each arena, with only one way to claim victory - be the last player to cash out before the inevitable explosion! If all players fail to cash out in time, the pot is equally divided among them. The Favela SkyBots is Toka’s flagship crash game, combining cutting-edge graphics and the thrilling crash genre into an unmatched PvP experience.`}
              />
            }
          />
          <Route
            path='games/bound-in-gold'
            element={
              <SingleGame
                name='Bound In Gold'
                headerImage={BoundInGold}
                video={'https://backendapist.blob.core.windows.net/images/Bound%20In%20Gold%203D%20Trailer.mp4'}
                gallery={[{ url: BoundGallery }]}
                assets={[
                  {
                    name: 'Download all',
                    link: 'https://backendapist.blob.core.windows.net/images/Bound_in_Gold_PromotionPackage.zip'
                  },
                  {
                    name: 'Art',
                    link: 'https://backendapist.blob.core.windows.net/images/Bound_in_Gold_art.zip'
                  },
                  {
                    name: 'Banners and Social Media',
                    link: 'https://backendapist.blob.core.windows.net/images/Bound_in_Gold_banners_and_social_media.zip'
                  },
                  {
                    name: 'Logo and Thumbnails',
                    link: 'https://backendapist.blob.core.windows.net/images/Bound_in_Gold_logo_and_thumbnails.zip'
                  },
                  {
                    name: 'Screenshots',
                    link: 'https://backendapist.blob.core.windows.net/images/Bound_in_Gold_screenshots.zip'
                  },
                  // {
                  //   name: 'Videos',
                  //   link: 'https://backendapist.blob.core.windows.net/images/FTN_Arena_videos.zip'
                  // },
                  {
                    name: 'Symbols',
                    link: 'https://backendapist.blob.core.windows.net/images/Bound_in_Gold_symbols.zip'
                  },
                  {
                    name: 'Game Sheet',
                    link: 'https://backendapist.blob.core.windows.net/images/Tokacity_BOUND%20IN%20GOLD_Gamesheet-compressed_2.pdf'
                  }
                ]}
                about={`Get ready to experience fast-paced, real-time multiplayer action in Bound In Gold, a visually immersive 3D Player vs. Player dodge crash game. Up to 10 players compete in each arena, placing equal bets at the start of each round to form a pot, and the last player standing wins it all! When the round begins, players must avoid deadly lasers. Bound In Gold, Toka’s flagship dodge crash game, offers a high-stakes, winner-takes-all experience in a competitive PvP experience.`}
              />
            }
          />
        </Routes>
      </div>
      <div className='client-area-footer'>
        <Footer />
      </div>
    </div>
  );
}
