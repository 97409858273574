import { ethers } from 'ethers';
import { getAddress } from '@ethersproject/address';
import { throws } from 'assert';

/**
 * We use setTimeout function, because some wallet couldn't handle this request immediately after sign in
 */

let timeoutId: NodeJS.Timeout;
export async function signMessage(
  provider: ethers.providers.Web3Provider | null,
  account: string,
  nonce: string
): Promise<string> {
  // This is because we have some strange behavior when we try to connect to metamask and we are triggered
  // this function many times that's why we have to clean the function if we fire many times and to be run only one time.
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  return new Promise((resolve, reject) => {
    timeoutId = setTimeout(async () => {
      const signer = getSigner(provider, account);
      await signer
        .signMessage(nonce)
        .then((result: string) => {
          resolve(result);
        })
        .catch((error: any) => {
          console.log(error);
          reject(error);
        });
    }, 500);
  });
}

function isAddress(value: string) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

function getSigner(provider: ethers.providers.Web3Provider | null, account: string) {
  if (!provider) {
    throw new Error('Need to provide provider!');
  }
  return provider.getSigner(account).connectUnchecked();
}
