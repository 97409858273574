import { useNavigate } from 'react-router-dom';

import './ErrorPage.scss';

function ErrorPage(props: any) {
  const navigate = useNavigate();
  return (
    <div className='error-page-wrapper'>
      <div className='contents'>
        <h1>Looks like you've been lost!</h1>
        <h2>404</h2>
        <p>Page not found</p>
        <button
          className='yellow-btn'
          onClick={() => {
            navigate(-1);
          }}
        >
          {' '}
          Back to previous
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
