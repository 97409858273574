import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';

import { ConnectionProvider } from './context/Connection';
import { AuthControllerProvider } from './context/AuthController';
import { ToastContainer } from 'react-toastify';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <React.Fragment>
      <BrowserRouter>
        <ConnectionProvider>
          <AuthControllerProvider>
            <App />
            <ToastContainer />
          </AuthControllerProvider>
        </ConnectionProvider>
      </BrowserRouter>
    </React.Fragment>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
