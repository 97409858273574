import { useContext, useEffect, useRef, useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import ProgressBar from './components/ProgressBar/ProgressBar';

import { ReactComponent as ProfileIcon } from '../../images/icons/profile_icon.svg';
import LoginImage from '../../images/login/login.png';
import LoginBackground from '../../images/login/background-login.svg';
import UploadBackground from '../../images/login/upload-background.svg';
import TokaCity from '../../images/login/toka-city.png';
import StartYourJourney from '../../images/login/start-your-journey.png';
import CloseButton from '../../images/login/close-button.png';
import RedX from '../../images/login/red-x.png';
import RedExclamation from '../../images/login/red-exclamation.png';
import UploadFolder from '../../images/login/upload-folder.png';
import MessageEdit from '../../images/login/message-edit.png';
import Google from '../../images/login/G.png';
import { ReactComponent as FolderAdd } from '../../images/icons/folderadd.svg';
import { postRegister } from '../../services/loginServic';

import { ConnectionContext } from '../../context/Connection';
import { AuthControllerContext } from '../../context/AuthController';

import { signMessage } from '../../helpers/ethers';
import * as fetcher from '../../helpers/fetcher';

import { SERVER_URL } from '../../constants';
import MobileStartJourney from '../../images/main stone.png';
import PlayBtn from '../../images/play_btn.png';
import './Login.scss';

interface SelectedImageFunction {
  (e: any): Promise<void>;
}

const supportedTypes = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'audio/mpeg3',
  'audio/mpeg4',
  'image/svg+xml'
];

interface ILogin {
  setLoginSteps: React.Dispatch<React.SetStateAction<number>>;
  loginSteps: number;
}

const IMAGE_SIZE = 10485760; // This is 10mb in bytes

function Login({ loginSteps, setLoginSteps }: ILogin) {
  const { connect, wallet, provider } = useContext(ConnectionContext);
  const {
    setIsSignMessage,
    disconnectUser,
    setShowChangeAddressModal,
    showChangeAddressModal,
    setUser,
    getUser,
    user
  } = useContext(AuthControllerContext);

  function disableScroll() {
    document.body.classList.add('stop-scrolling');
  }

  function enableScroll() {
    document.body.classList.remove('stop-scrolling');
  }

  const [startKyc, setStartKyc] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [imageSize, setImageSize] = useState<string>('');
  const [imageName, setImageName] = useState<string>('');
  const [imageData, setImageData] = useState<any>(undefined);
  const [formDataImage, setFormDataImage] = useState<any>('');
  const [imageError, setImageError] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [errorNickname, setErrorNickname] = useState<string>('');
  const [errorRequest, setErrorRequest] = useState<string>('');
  const [signatureOnRegister, setSignatureOnRegister] = useState<string>('');
  const documentSelectorRef = useRef<HTMLInputElement>(null);
  const isDesktop = !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [nextBtn, setNextBtn] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [previousLoginSteps, setPreviousLoginSteps] = useState<number>(0);
  const [inputHash, setInputHash] = useState<string>('');
  const [inputFirstNamePromocode, setInputFirstNamePromocode] = useState<string>('');
  const [inputLastNamePromocode, setInputLastNamePromocode] = useState<string>('');
  const [inputNicknamePromocode, setInputNicknamePromocode] = useState<string>('');
  const [inputEmailPromocode, setInputEmailPromocode] = useState<string>('');
  const [inputPromocode, setInputPromocode] = useState<string>('');
  const [responseData, setResponseData] = useState<null | string>(null);
  const [isPromoSubmitted, setPromoSubmitted] = useState<boolean>(false);
  const progressSteps = ['Connect Wallet', 'Create profile', 'Complete KYC', 'Start playing'];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    loginSteps == 0 ? enableScroll() : disableScroll();
    loginSteps == 6 &&
      getUser().then((user: any) => {
        user;
      });
  }, [loginSteps]);

  useEffect(() => {
    if (showChangeAddressModal) {
      setLoginSteps(2);
    } else {
      setLoginSteps(0);
    }
  }, [showChangeAddressModal]);

  useEffect(() => {
    accountLogin();
  }, [provider, wallet]);

  useEffect(() => {
    if (nickname.length >= 3) {
      setNextBtn(true);
    } else if (nextBtn) {
      setNextBtn(false);
    }
  }, [nickname]);

  useEffect(() => {
    if (imageName && imageData.size > IMAGE_SIZE) {
      setImageError('The size of the image need to be les then 10 MB!');
    } else {
      setImageError('');
    }
  }, [imageName]);

  async function accountLogin() {
    if (isConnected() && !localStorage.getItem('bt') && wallet!.accounts[0].address) {
      if (isDesktop) {
        getSignMessage();
      } else {
        setLoginSteps(3);
      }
    } else if (localStorage.getItem('bt') && !wallet) {
      const bt = localStorage.getItem('bt');
      if (bt) {
        const autoLogin = await getUser(bt);
        if (autoLogin && typeof autoLogin === 'object' && 'walletId' in autoLogin) {
          if (isValidEthereumAddress(autoLogin.walletId)) {
            disconnectUser();
          }
        }
      }
    }
  }

  function isValidEthereumAddress(address: any): boolean {
    const ethereumAddressRegex = /^0x[0-9a-fA-F]{40}$/;
    return ethereumAddressRegex.test(address);
  }

  async function loginError(error: any) {
    setPreviousLoginSteps(loginSteps);
    setLoginSteps(9);
    await disconnectUser(true);
    setShowChangeAddressModal(false);
  }

  async function onConnect(): Promise<void> {
    setIsSignMessage(true);
    await connect().catch((err) => {
      console.error(err.message);
    });
  }

  const selectedImage: SelectedImageFunction = async (e: any) => {
    const file = e.target.files[0];
    setImageData(file);

    if (
      supportedTypes.find((type) => type === file.type)
      // TODO: When we now what size we need for the image be upload
      //  && file.size < 1048576000
    ) {
      const image = {
        path: URL?.createObjectURL(file),
        file: file
      };

      if (file.size > 0) {
        setImageSize(formatBytes(file.size));
      }

      if (file.name) {
        setImageName(file.name);
      }

      const formData = new FormData();
      formData.append('avatar', file);

      setFormDataImage(formData);
      setImage(image.path);
      setImageError('');
    }
  };

  function formatBytes(bytes: any, decimals: number = 2): string {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  // TODO: If we want to have button to remove image from the input fields before upload image
  function removeFile(): void {
    setImageSize('');
    setImageName('');
    setImage('');
    setImageError('');
  }

  async function handleSubmit(e: any): Promise<void> {
    e.preventDefault(`handleSubmit`);
    if (nickname.length < 3) {
      setErrorNickname('The nickname must be longer then 3 character!');
      return;
    } else if (nickname.length > 20) {
      setErrorNickname('The nickname must be less then 20 character!');
      return;
    }

    if (wallet?.accounts[0].address) {
      try {
        const userResult = await postRegister(wallet?.accounts[0].address, signatureOnRegister, nickname, imageData);
        localStorage.setItem('bt', userResult.accessToken);
        localStorage.setItem('address', wallet?.accounts[0].address);

        setUser(userResult.user);
        setLoginSteps(5);
        setNextBtn(false);
        setErrorRequest('');
        setErrorNickname('');
      } catch (error: any) {
        if (error.message == 'Username is taken.') {
          setErrorNickname('This name is already taken. Please choose a new one!');
        } else {
          setErrorRequest('Something went wrong, please try again.');
        }
      }
    }
  }

  const isConnected = (): boolean => {
    if (provider && wallet?.accounts[0].address) {
      return true;
    } else {
      return false;
    }
  };

  const requestLogin = async (signature: string) => {
    const accessData = await fetcher.post('/auth/login', {}, { walletAddress: wallet!.accounts[0].address, signature });

    localStorage.setItem('bt', accessData.accessToken);
    localStorage.setItem('address', wallet!.accounts[0].address);

    return accessData.accessToke;
  };

  const getCurrentUser = async (): Promise<any> => {
    const currentUserInfo = await fetcher.get(`/auth/init-login?address=${wallet?.accounts[0].address}`, {}, {});

    return currentUserInfo;
  };

  const getSignMessage = async () => {
    // TODO: Hare we are checking the chan for feature implementation
    // if (wallet?.chains[0]?.id === ('0x5' || '0x1')) {

    try {
      if (isConnected()) {
        if (!localStorage.getItem('bt')) {
          const currentUserInfo = await getCurrentUser();

          if (currentUserInfo.registered) {
            const signature = await getSignature(wallet!.accounts[0].address, currentUserInfo.messageToSign);

            if (wallet?.accounts[0].address) {
              const token = await requestLogin(signature);

              const currentUser: any = await getUser(token);

              if (currentUser) {
                if (currentUser.kycState == 'valid' || currentUser.kycState == 'pending') {
                  setLoginSteps(6);
                } else {
                  setLoginSteps(5);
                }
                setShowChangeAddressModal(false);
              } else {
                setPreviousLoginSteps(loginSteps);

                setLoginSteps(9);
              }

              // window.location.href = window.location.origin;
            }
          } else {
            const signature = await getSignature(wallet!.accounts[0].address, currentUserInfo.messageToSign);

            await getUser();
            setShowChangeAddressModal(false);

            setLoginSteps(4);

            if (wallet?.accounts[0].address) {
              setSignatureOnRegister(signature);
            }
          }
          setIsSignMessage(false);
        }
      }
    } catch (error: any) {
      loginError(error);
    }
  };

  const getSignature = async (address: string, messageToSign: string): Promise<string> => {
    setTimeout(() => {
      setLoginSteps(3);
    }, 700);
    const signature = await signMessage(provider, address, messageToSign);

    return signature;
  };

  function closeLoginModal(e: any, reloadPage?: boolean) {
    const outSideModal = document.querySelector('.login-wrapper');
    if (e.target == outSideModal) {
      setLoginSteps(0);
      if (reloadPage) {
        disconnectUser();
        window.location.reload();
      }
    }
  }

  async function handleChangeAccount() {
    setLoginSteps(3);
    localStorage.removeItem('bt');
    localStorage.removeItem('address');
    setShowChangeAddressModal(false);
    accountLogin();
  }

  //first view
  const viewLoginFirstView = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={(e) => {
          closeLoginModal(e);
        }}
      >
        <div className='login-background position-relative'>
          <div className='mobile-background position-relative h-100 w-100'>
            <div className='gradient-overlay' />
            <img
              className='stone-image'
              src={windowWidth > 767 ? StartYourJourney : MobileStartJourney}
              alt='start-image'
            />
            <div className='close-btn position-absolute'>
              <img src={CloseButton} alt='close-button' onClick={() => setLoginSteps(0)} />
            </div>
          </div>
          <div className='content-wrapper d-flex flex-column align-items-center justify-content-center position-absolute w-100 h-100'>
            <h1 className='first-login-header position-static'>Unleash your potential in the future</h1>
            <p className='first-login-description position-static mt-4'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever
            </p>
            <button
              className='yellow-btn position-static mt-5'
              onClick={() => {
                onConnect();
                setLoginSteps(0);
              }}
            >
              Start your journey
            </button>
            <div className='demo-btn-text'>
              <button
                className='yellow-btn-demo'
                style={{ overflow: 'hidden' }}
                onClick={() => {
                  setLoginSteps(10);
                }}
              >
                <div className='profile-demo'>
                  <ProfileIcon
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                </div>
              </button>
              <p className='demo-text'>Demo User</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // View for sign message
  const viewLoginSignMessage = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={(e) => {
          closeLoginModal(e, true);
        }}
      >
        <div className='login-background position-relative h-100'>
          <div className='close-btn position-absolute'>
            <img
              src={CloseButton}
              alt='close-button'
              onClick={() => {
                setLoginSteps(0);
                disconnectUser();
                window.location.reload();
              }}
            />
          </div>
          {windowWidth > 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}

          {/* <section className='section-2 mb-150 h-100 w-100 d-flex flex-column justify-content-between  align-items-center'> */}
          <section className='section-2 h-100 w-100 d-flex flex-column justify-content-between  align-items-center'>
            <div className='d-flex flex-column justify-content-center align-items-center w-100'>
              <h1 className='login-header text-center'>Sign Message</h1>
              <p className='login-description mt-5'>To continue you need to sign message in your wallet!</p>
            </div>

            <div className='w-100'>
              {isDesktop ? (
                ''
              ) : (
                <div className='d-flex justify-content-center position-relative position-md-absolute bottom-0'>
                  <button
                    className={`btn-next ${windowWidth > 767 ? 'mt-180' : ''}`}
                    onClick={(e: any) => {
                      e.preventDefault();

                      /**
                       * :TODO When metamask fix issue https://github.com/MetaMask/metamask-mobile/issues/6736 then we can add this
                       * line to disabled button because now if we want to connect with metamask the user need to click two times on this button
                       */
                      // e.target.classList.add('disabled');
                      setTimeout(() => {
                        getSignMessage();
                      }, 500);
                    }}
                  >
                    Next <span className='arrow-font'>-&gt;</span>
                  </button>
                </div>
              )}
              {windowWidth <= 767 ? (
                <ProgressBar
                  loginSteps={loginSteps}
                  previousLoginSteps={previousLoginSteps}
                  isDesktop={windowWidth > 767}
                />
              ) : (
                ''
              )}
            </div>
          </section>
        </div>
      </div>
    );
  };

  const viewError = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={() => {
          setLoginSteps(0);
          disconnectUser();
          window.location.reload();
        }}
      >
        <div className='login-background justify-content-around justify-content-md-start position-relative'>
          <div className='close-btn position-absolute'>
            <img
              src={CloseButton}
              alt='close-button'
              onClick={() => {
                setLoginSteps(0);
                disconnectUser();
                window.location.reload();
              }}
            />
          </div>
          {windowWidth > 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}

          <section className='section-2 d-flex flex-column justify-content-center align-items-center'>
            <h1 className='login-header text-danger'>Something when wrong</h1>
            <p className='login-description mt-5'>Something when wrong please try again later!</p>
          </section>
          {windowWidth <= 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  const viewLoginKyc = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={(e: any) => closeLoginModal(e)}
      >
        <div className='login-background position-relative'>
          <div className='d-flex flex-column flex-1 justify-content-between'>
            <div>
              <div className='close-btn position-absolute'>
                <img
                  src={CloseButton}
                  alt='close-button'
                  onClick={() => {
                    setLoginSteps(0);
                  }}
                />
              </div>
              {windowWidth > 767 ? (
                <ProgressBar
                  loginSteps={loginSteps}
                  previousLoginSteps={previousLoginSteps}
                  isDesktop={windowWidth > 767}
                />
              ) : (
                ''
              )}

              <section className='section-2 d-flex flex-column justify-content-center align-items-center mt-5 pt-4'>
                {user.avatarUrl ? (
                  <img className='profile-image' src={`${SERVER_URL}/${user.avatarUrl}`} alt='profile-image' />
                ) : (
                  ''
                )}
                <h1 className='login-header m-sm-5 m-0 mt-3 px-5'>Hi, {user.nickname}, please take KYC process!</h1>
                <p className='login-description '>Just one more little step and you’re good to go!</p>
              </section>

              <section className='kyc-btn-section mb-5 mt-5 d-flex justify-content-center '>
                {!startKyc ? (
                  <>
                    {isDesktop ? (
                      <button
                        className='yellow-btn position-static '
                        onClick={() => {
                          setStartKyc(true);
                        }}
                      >
                        Start kyc process
                      </button>
                    ) : (
                      <Link
                        to='/kyc'
                        className='yellow-btn position-static '
                        onClick={() => {
                          setLoginSteps(0);
                        }}
                      >
                        Start kyc process
                      </Link>
                    )}
                    <Link
                      to='/'
                      className={`${windowWidth > 767 ? 'ms-5' : 'mt-5'} regular-btn d-flex align-items-center`}
                      onClick={() => setLoginSteps(0)}
                    >
                      No, take me to my profile
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to='/kyc?mobile=true'
                      className='yellow-btn position-static'
                      onClick={() => {
                        setLoginSteps(0);
                      }}
                    >
                      Mobile device
                    </Link>
                    <Link
                      to='/kyc?mobile=false'
                      className='yellow-btn position-static ms-3'
                      onClick={() => {
                        setLoginSteps(0);
                      }}
                    >
                      Desktop
                    </Link>
                  </>
                )}
              </section>
            </div>

            {windowWidth <= 767 ? (
              <ProgressBar
                loginSteps={loginSteps}
                previousLoginSteps={previousLoginSteps}
                isDesktop={windowWidth > 767}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  };

  const viewLoginSwitchAccount = () => {
    return (
      <div className='login-wrapper d-flex justify-content-center align-items-center'>
        <div className='login-background position-relative'>
          <div className='close-btn position-absolute'>
            <img
              src={CloseButton}
              alt='close-button'
              onClick={() => {
                setLoginSteps(0);
                disconnectUser();
                window.location.reload();
              }}
            />
          </div>
          {windowWidth > 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}

          <section className='section-2 d-flex flex-column justify-content-center align-items-center mt-5 pt-4'>
            {user?.avatarUrl ? (
              <img className='profile-image' src={`${SERVER_URL}/${user.avatarUrl}`} alt='profile-image' />
            ) : (
              ''
            )}
            <h1 className='login-header m-5 mt-3 px-5'>Switch account</h1>
            <p className='login-description '>
              Are you sure you want to change your current wallet account from {localStorage.getItem('address')} to{' '}
              {wallet?.accounts[0].address}{' '}
            </p>
          </section>

          <section className='mb-5 mt-5 d-flex justify-content-center '>
            <button
              className='yellow-btn position-static '
              onClick={() => {
                handleChangeAccount();
              }}
            >
              Switch to new account
            </button>
            <button
              className='regular-btn ms-5 d-flex align-items-center'
              onClick={() => {
                setLoginSteps(0);
                disconnectUser();
                window.location.reload();
              }}
            >
              Disconnect
            </button>
          </section>
          {windowWidth <= 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  const viewLoginWelcome = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={(e: any) => closeLoginModal(e)}
      >
        <div className='login-background position-relative h-100'>
          <div className='close-btn position-absolute'>
            <img
              src={CloseButton}
              alt='close-button'
              onClick={() => {
                setLoginSteps(0);
              }}
            />
          </div>
          {windowWidth > 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}

          <section className='section-2 h-100 w-100 d-flex flex-column justify-content-between justify-content-md-center align-items-center'>
            <div className='d-flex flex-column justify-content-center align-items-center w-100'>
              <h1 className='login-header'>You can start Playing!</h1>
              <Link
                to='/game-start'
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  position: 'absolute',
                  top: '55%'
                }}
                onClick={() => {
                  setLoginSteps(0);
                }}
              >
                {/* <button className='button-connect play'>Play the game</button> */}
                <div className='play-btn-wrapper'>
                  <span className='play-text'>PLAY NOW!</span>
                  <div className='bg'>
                    <img src={PlayBtn} />
                  </div>
                </div>
              </Link>
            </div>
            <div className='w-100'>
              {windowWidth <= 767 ? (
                <ProgressBar
                  loginSteps={loginSteps}
                  previousLoginSteps={previousLoginSteps}
                  isDesktop={windowWidth > 767}
                />
              ) : (
                ''
              )}
            </div>
          </section>
        </div>
      </div>
    );
  };

  // Write nickname
  const viewLoginRegisterUser = () => {
    return (
      <div className='login-wrapper d-flex justify-content-center align-items-center'>
        <div className='login-background overflow-y-scroll position-relative h-100'>
          <div
            className='close-btn position-absolute'
            onClick={() => {
              setLoginSteps(0);
              disconnectUser();
              window.location.reload();
            }}
          >
            <img src={CloseButton} alt='close-button' />
          </div>
          {windowWidth > 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}

          <section className='section-2 d-flex flex-column align-items-center'>
            <h1 className='login-header'> Choose your nickname</h1>
            <p className='login-description mt-4 mx-5'>
              Choose your avatar and nickname that will appear on your profile here and In-Game. You can’t change your
              nickname once you set it up!
            </p>
            {errorRequest ||
              (imageError && (
                <div className='d-flex justify-content-center align-items-center mt-3'>
                  <img className='w-h-20' src={RedExclamation} alt='exlamation-image' />
                  <p className='nickname-description-taken ms-2'>{errorRequest || imageError}</p>
                </div>
              ))}
          </section>

          <section className='upload-image-nickname d-flex flex-column align-items-center mt-5'>
            <div
              className='image-wrapper d-flex flex-column align-items-center'
              onClick={() => documentSelectorRef?.current?.click()}
            >
              {image?.length ? (
                <>
                  <img className='slam-image w-100' src={image} alt='avatar' />
                  <span className='mt-1'>{imageSize}</span>
                </>
              ) : (
                <div className='no-image w-100 h-100'>
                  <FolderAdd />
                  <p>Upload image</p>
                </div>
              )}

              <input
                onChange={selectedImage}
                accept='image/png, image/jpeg,image/gif,image/webp,audio/mpeg3,audio/mpeg4'
                style={{ display: 'none' }}
                type='file'
                ref={documentSelectorRef}
              />
            </div>

            <div className='d-flex flex-column justify-content-center align-items-center position-relative mx-5 mt-4  mb-5'>
              <div className='d-flex justify-content-center'>
                <input
                  type='text'
                  className={`nickname-input mb-2 ${errorNickname ? 'nickname-taken' : ''}`}
                  id='nicknameInput'
                  placeholder='Nickname'
                  defaultValue={nickname}
                  onChange={(e) => {
                    setErrorNickname('');
                    setErrorRequest('');
                    setNickname(e.target.value);
                  }}
                />

                {errorNickname && (
                  <img
                    className='red-x'
                    src={RedX}
                    alt='close-icon'
                    onClick={() => {
                      setErrorNickname('');
                    }}
                  />
                )}
              </div>
              {errorNickname && (
                <div className='d-flex justify-content-center align-items-center'>
                  <img className='w-h-20' src={RedExclamation} alt='exlamation-image' />
                  <p className='nickname-description-taken ms-2'>{errorNickname}</p>
                </div>
              )}
            </div>

            {/* TODO: when we know will we have image to click and after that show fields to write a nickname  */}
            {/* {writeNickname ? (
              <div className='w-100 d-flex justify-content-center position-relative mt-4'>
                <input
                  type='text'
                  className='nickname-input mb-5'
                  id='nicknameInput'
                  placeholder='Nickname'
                  defaultValue={nickname}
                  onChange={(e) => {
                    setNickname(e.target.value);
                  }}
                />
              </div>
            ) : (
              <div className='d-flex my-4 '>
                <div className='google-wrapper pointer d-flex position-relative'>
                  <img src={Google} className='pb-3 m-1' alt='google-icon' />
                </div>
                <div className='d-flex pointer ms-3 mb-3' onClick={() => setWriteNickname(true)}>
                  <img src={MessageEdit} className='' alt='edit-icon' />
                </div>
              </div>
            )} */}
          </section>

          <div className='btn-next-wr d-flex justify-content-center'>
            <button
              className={`btn-next ${nextBtn ? '' : 'disabled'} ${
                (errorNickname || imageError) && 'disabled'
              }  position-relative`}
              onClick={(e: any) => {
                if (!e.target || !nextBtn || errorNickname || imageError) {
                  return;
                }

                const classPropertiesArray = Array.from(e.target.classList);
                if (classPropertiesArray.includes('disabled') == false) {
                  handleSubmit(e);
                }
              }}
            >
              Next <span className='arrow-font'>-&gt;</span>
            </button>
          </div>
          {windowWidth <= 767 ? (
            <ProgressBar
              loginSteps={loginSteps}
              previousLoginSteps={previousLoginSteps}
              isDesktop={windowWidth > 767}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };
  // console.log('loginStepsloginStepsloginSteps', loginSteps);

  const handleSubmitDemo = async (e: any) => {
    e.preventDefault();
    try {
      const response = await fetcher.post('/auth/login-demo-users', {}, { hash: inputHash });
      const token = response.accessToken;
      localStorage.setItem('bt', token);

      if (token) {
        getUser(token);
        setLoginSteps(6);
      } else {
        setResponseData('Something went wrong');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponseData('Invalid hash! Demo user not found!');
    }
    setTimeout(() => {
      setResponseData(null);
    }, 5000);
  };

  const handleSubmitPromocode = async (e: any) => {
    e.preventDefault();

    try {
      const responseUser = await fetcher.post('/auth/login-with-promo-code', {}, { promoCode: inputPromocode });
      const token = responseUser.accessToken;
      localStorage.setItem('bt', token);

      if (token) {
        getUser(token);
        setLoginSteps(6);
      }
    } catch (error: any) {
      if (error.message == 'USER_NOT_FOUND_ERROR') {
        setLoginSteps(12);
      } else {
        try {
          setResponseData('Invalid promo code! Please double-check the code and try again!');
        } catch {
          console.error('Error fetching data:', error);
          setResponseData('Invalid promo code!');
        }
      }
    }
    setTimeout(() => {
      setResponseData(null);
    }, 5000);
  };

  const handleSubmitSetPromocode = async (e: any) => {
    e.preventDefault();
    if (inputFirstNamePromocode && inputLastNamePromocode && inputNicknamePromocode && inputEmailPromocode) {
      console.log('vutre');
      try {
        const response = await fetcher.post(
          '/auth/create-demo-user-with-promo-code',
          {},
          {
            promoCode: inputPromocode,
            nickname: inputNicknamePromocode,
            email: inputEmailPromocode,
            firstName: inputFirstNamePromocode,
            lastName: inputLastNamePromocode
          }
        );

        if (response) {
          const responseUser = await fetcher.post('/auth/login-with-promo-code', {}, { promoCode: inputPromocode });
          const token = responseUser.accessToken;
          localStorage.setItem('bt', token);
          console.log(response);

          if (token) {
            getUser(token);
            setLoginSteps(6);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setResponseData(`${error}`);
      }
      setTimeout(() => {
        setResponseData(null);
      }, 5000);
    }
  };

  const viewDemoUser = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={(e) => {
          closeLoginModal(e);
        }}
      >
        <div className='login-background position-relative'>
          <div className='mobile-background position-relative h-100 w-100'>
            <div className='gradient-overlay' />
            <img
              className='stone-image'
              src={windowWidth > 767 ? StartYourJourney : MobileStartJourney}
              alt='start-image'
            />
            <div className='close-btn position-absolute'>
              <img src={CloseButton} alt='close-button' onClick={() => setLoginSteps(0)} />
            </div>
          </div>
          <div className='content-wrapper d-flex flex-column align-items-center justify-content-center position-absolute w-100 h-100'>
            <form className='demo-from' onSubmit={handleSubmitDemo}>
              <h3 className='first-login-header position-static demo-h1 '>Enter Hash:</h3>
              <input
                className='demo-input'
                type='text'
                value={inputHash}
                onChange={(e) => setInputHash(e.target.value)}
              />
              <button className='yellow-btn position-static demo-btn' type='submit'>
                Get Demo User
              </button>
              <h2 className={responseData ? 'demo-res' : 'demo-res-hidden'}>{responseData}</h2>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const viewPromocodeAccess = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={(e) => {
          closeLoginModal(e);
        }}
      >
        <div className='login-background position-relative'>
          <div className='mobile-background position-relative h-100 w-100'>
            <div className='gradient-overlay' />
            <img
              className='stone-image'
              src={windowWidth > 767 ? StartYourJourney : MobileStartJourney}
              alt='start-image'
            />
            <div className='close-btn position-absolute'>
              <img src={CloseButton} alt='close-button' onClick={() => setLoginSteps(0)} />
            </div>
          </div>
          <div className='content-wrapper d-flex flex-column align-items-center justify-content-center position-absolute w-100 h-100'>
            <form className='demo-from' onSubmit={handleSubmitPromocode}>
              <h3 className='first-login-header position-static promo-h1 '>
                Begin your journey using your personal promo code
              </h3>
              <p className='first-login-description position-static mt-2'>
                Enter your 8 digit promo code below to register
              </p>
              <input
                className='demo-input'
                type='text'
                value={inputPromocode}
                onChange={(e) => setInputPromocode(e.target.value)}
              />
              <button className='yellow-btn position-static promo-btn' type='submit'>
                Play now
              </button>
              <h2 className={responseData ? 'demo-res' : 'demo-res-hidden'}>{responseData}</h2>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const viewSetPromocode = () => {
    return (
      <div
        className='login-wrapper d-flex justify-content-center align-items-center'
        onClick={(e) => {
          closeLoginModal(e);
        }}
      >
        <div className='login-background position-relative'>
          <div className='mobile-background position-relative h-100 w-100'>
            <div className='gradient-overlay' />
            <img
              className='stone-image'
              src={windowWidth > 767 ? StartYourJourney : MobileStartJourney}
              alt='start-image'
            />
            <div className='close-btn position-absolute'>
              <img src={CloseButton} alt='close-button' onClick={() => setLoginSteps(0)} />
            </div>
          </div>
          <div className='content-wrapper d-flex flex-column align-items-center justify-content-center position-absolute w-100 h-100'>
            <form className='demo-from mt-promo' onSubmit={handleSubmitSetPromocode}>
              <h3 className='first-login-header position-static get-promo-h1 '>
                Get the most out of your gaming experience!
              </h3>
              <p className='first-login-description position-static get-promo-p'>
                Take a moment to set up your personal information before you play.
              </p>
              <div className='promo-inputs'>
                <input
                  type='text'
                  className={
                    isPromoSubmitted && !inputFirstNamePromocode
                      ? 'collection_input w-input error'
                      : 'collection_input w-input'
                  }
                  placeholder={isPromoSubmitted && !inputFirstNamePromocode ? 'First name is required' : 'First name *'}
                  id='promo-first-name'
                  value={inputFirstNamePromocode}
                  onChange={(e) => setInputFirstNamePromocode(e.target.value)}
                />
                <input
                  type='text'
                  className={
                    isPromoSubmitted && !inputLastNamePromocode
                      ? 'collection_input w-input error'
                      : 'collection_input w-input'
                  }
                  placeholder={isPromoSubmitted && !inputLastNamePromocode ? 'Last name is required' : 'Last name *'}
                  id='promo-last-name'
                  value={inputLastNamePromocode}
                  onChange={(e) => setInputLastNamePromocode(e.target.value)}
                />
                <input
                  type='text'
                  className={
                    isPromoSubmitted && !inputNicknamePromocode
                      ? 'collection_input w-input error'
                      : 'collection_input w-input'
                  }
                  placeholder={isPromoSubmitted && !inputNicknamePromocode ? 'Nickname is required' : 'Nickname *'}
                  id='promo-nickname'
                  value={inputNicknamePromocode}
                  onChange={(e) => setInputNicknamePromocode(e.target.value)}
                />
                <input
                  type='email'
                  className={
                    isPromoSubmitted && !inputEmailPromocode
                      ? 'collection_input w-input error'
                      : 'collection_input w-input'
                  }
                  placeholder={isPromoSubmitted && !inputEmailPromocode ? 'Email is required' : 'Email *'}
                  id='promo-email'
                  value={inputEmailPromocode}
                  onChange={(e) => setInputEmailPromocode(e.target.value)}
                />
              </div>

              <button
                className='yellow-btn position-static promo-btn mt-4'
                type='submit'
                onClick={() => {
                  setPromoSubmitted(true);
                }}
              >
                Play now
              </button>
              <h2 className={responseData ? 'demo-res promo-res' : 'demo-res-hidden'}>{responseData}</h2>
            </form>
          </div>
        </div>
      </div>
    );
  };

  function renderView() {
    switch (loginSteps) {
      case 0:
        return <></>;
      case 1:
        return viewLoginFirstView();
      case 2:
        return viewLoginSwitchAccount();
      case 3:
        return viewLoginSignMessage();
      case 4:
        return viewLoginRegisterUser(); // View to write nickname and upload avatar
      case 5:
        return viewLoginKyc();
      case 6:
        return viewLoginWelcome(); //
      case 9:
        return viewError();
      case 10:
        return viewDemoUser();
      case 11:
        return viewPromocodeAccess();
      case 12:
        return viewSetPromocode();
      default:
        return <></>;
    }
    return <></>;
  }
  return renderView();
}

export default Login;
