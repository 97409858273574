import './Games.scss';
import Banner from './components/Banner';
import GameCard from './components/GameCard';
import AscendThumbnail from '../../../images/client-hub/thumbnail_ascend.png';
import FTNThumbnail from '../../../images/client-hub/thumbnail_ftn.png';
import FavelaThumbnail from '../../../images/client-hub/favela-thumbnail.png'
import BoundThumbanil from '../../../images/client-hub/bound-thumbnail.png'

export default function Games() {
  return (
    <div className='client-hub-games'>
      <div className='title'>
        <h1>Which game are you looking for?</h1>
      </div>
      <div className='games-content'>
        <GameCard
          gameImage={AscendThumbnail}
          gameName='Ascend Arena'
          gameType='PvP Crash Games'
          url='ascend-arena'
        />
        <GameCard
          gameImage={FTNThumbnail}
          gameName='FTN Arena'
          gameType='PvP Crash Games'
          url='ftn-arena'
        />
        <GameCard
          gameImage={FavelaThumbnail}
          gameName='Favela Skybots'
          gameType='PvP Crash Games'
          url='favela-skybots'
        />
        <GameCard
          gameImage={BoundThumbanil}
          gameName='Bound in Gold'
          gameType='PvP Crash Games'
          url='bound-in-gold'
        />
      </div>
      <Banner />
    </div>
  );
}
