import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import coinbaseModule from '@web3-onboard/coinbase';

import { init } from '@web3-onboard/react';

import MetaSavages from '../images/login/meta-savages.svg';
import MetaSavagesSmall from '../images/login/meta-savages-small.svg';
import TokaCity100 from '../images/login/toka-city-100.png';
import TokaCitySmall from '../images/login/toka-city-small.png';

import { PROVIDER_API_KEY, WALLETCONNECT_PROJECT_ID } from './../constants';
const coinbase = coinbaseModule();

const injected = injectedModule();
const walletConnect = walletConnectModule({
  projectId: WALLETCONNECT_PROJECT_ID,
  version: 2,
  requiredChains: []
});

const ThemingMap = {
  '--w3o-background-color': 'red',
  // '--w3o-font-family'?: string
  '--w3o-foreground-color': 'blue',
  '--w3o-text-color': 'yellow',
  '--w3o-border-color': 'green'
  // '--w3o-action-color'?: string
  // '--w3o-border-radius'?: string
};

export const initWeb3Onboard = init({
  wallets: [injected, coinbase, walletConnect],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: `https://mainnet.infura.io/v3/${PROVIDER_API_KEY}`
    },
    {
      id: '0x5',
      token: 'ETH',
      label: 'Goerli Testnet',
      rpcUrl: `https://goerli.infura.io/v3/${PROVIDER_API_KEY}`
    },
    {
      id: '0xaa36a7',
      token: 'ETH',
      label: 'Sepolia Testnet',
      rpcUrl: `https://sepolia.infura.io/v3/${PROVIDER_API_KEY}`
    },
    {
      id: 1337 as any,
      token: 'ETH',
      label: 'Hardhat',
      rpcUrl: 'http://127.0.0.1:8545/'
    }
  ],
  accountCenter: {
    desktop: {
      enabled: false
    },
    mobile: {
      enabled: false
    }
  },

  appMetadata: {
    name: 'Toka City',
    icon: `${TokaCitySmall}`,
    logo: `${TokaCity100}`,
    description: 'Metasavages',
    recommendedInjectedWallets: [
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
      { name: 'MetaMask', url: 'https://metamask.io' }
    ],
    explore: window.location.origin
  },

  theme: 'dark'
});
