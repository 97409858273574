import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import animationData from '../../video/lottie/loading-casino.json'; // Import your Lottie animation JSON file

import './Loading.scss';

function Loading() {
  const animationContainer = useRef(null);

  useEffect(() => {
    // Create a new Lottie animation
    let anim: AnimationItem | undefined;
    if (animationContainer.current) {
      anim = lottie.loadAnimation({
        container: animationContainer.current,
        animationData: animationData,
        renderer: 'svg', // Choose the renderer you want to use (svg, canvas, html)
        loop: true,
        autoplay: true
      });
    }

    // Cleanup function to stop the animation on unmount
    return () => {
      if (anim) {
        anim.destroy();
      }
    };
  }, []);

  return (
    <div className='loading-spinner d-flex flex-column justify-content-center align-items-center'>
      <div ref={animationContainer} />
      <h1> Your luck is loading...</h1>
    </div>
  );
}

export default Loading;
